import "./CustomModal.css";
import { Modal } from "react-rainbow-components";
import { useState, useEffect } from "react";
import CustomVoltageRedChart from "./CustomVoltageRedChart";
import CustomCurrentRedChart from "./CustomCurrentRedChart";
import CustomActivePowerRedChart from "./CustomActivePowerRedChart";
import CustomReactivePowerRedChart from "./CustomReactivePowerRedChart";
import CustomPowerFactorRedChart from "./CustomPowerFactorRedChart";

const CustomModal = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [voltageRed, setVoltageRed] = useState([]);
    const [currentRed, setCurrentRed] = useState([]);
    const [pfRed, setPfRed] = useState([]);
    const [r_pRed, setR_pRed] = useState([]);
    const [a_pRed, setA_pRed] = useState([]);
    const [time, setTime] = useState([]);
    const [state, setState] = useState(true);

    const closeModalHandler = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        if (props.isopen !== undefined) {
            setIsOpen(true);
        }
    }, [props.isopen]);

    useEffect(() => {
        fetch("https://vishnusubstationbackend.onrender.com/exp/allsensordata")
            .then(async result => {
                const data = await result.json();
                console.log(data.data);
                const data1 = data.data.slice(-5);
                let vred = [], cred = [], pfred = [], rpred = [], apred = [], time1 = [];
                data1.map((v, i) => {
                    vred.push(v.voltageRed);
                    cred.push(v.currentRed);
                    pfred.push(v.pfRed);
                    const R_PRed = 3 * v.voltageRed * v.currentRed * Math.sin(Math.acos(v.pfRed));
                    rpred.push(R_PRed);
                    const date = new Date(v.createdAt);
                    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                    time1.push(formattedDate);
                    apred.push(v.a_pRed);
                });
                setVoltageRed(vred);
                setCurrentRed(cred);
                setPfRed(pfred);
                setA_pRed(apred);
                setR_pRed(rpred);
                setTime(time1);
                setState(!state);
            })
            .catch(error => {
                console.error(error);
            },5000);
    }, [state]);

    useEffect(() => {
        setState(!state);
    }, []);
    return (
        <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModalHandler}
                title="Charts"
                className="custom__modal"
            >
                <div className="grid-container">
                    <div className="grid-item">
                        <CustomVoltageRedChart time={time} value={voltageRed} />
                    </div>
                    <div className="grid-item">
                        <CustomCurrentRedChart time={time} value={currentRed} />
                    </div>
                    <div className="grid-item">
                        <CustomActivePowerRedChart time={time} value={pfRed} />
                    </div>
                    <div className="grid-item">
                        <CustomReactivePowerRedChart time={time} value={r_pRed} />
                    </div>
                    <div className="grid-item center">
                        <CustomPowerFactorRedChart time={time} value={pfRed} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CustomModal;
