import { useEffect, useState } from "react";
import "./CustomActivePowerRedChart.css";
import Chart from "react-apexcharts";

const CustomActivePowerRedChart = (props) => {
    const [chartconfig3, setChartconfig3] = useState();
    useEffect(() => {
        setChartconfig3({
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: props.time,
                    title: {
                        text: "Time"
                    }
                },
                yaxis: {
                    title: {
                        text: "ActivePower"
                    }
                },
                title:{
                    text:"ActivePower wrt to Time",
                    align:"center"
                },
                legend:{
                    show:true
                },
                dataLabels:{
                    enabled:true
                }
            },
            series: [
                {
                    name: "series-1",
                    data: props.value
                }
            ]
        });
        
    }, [props]);
    return (
        <div>
            {chartconfig3 ? <Chart
                options={chartconfig3.options}
                series={chartconfig3.series}
                type="line"
                width="500"
                margin-left="5rem"
            />:null}
        </div>
    );
}

export default CustomActivePowerRedChart;