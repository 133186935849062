import "./Dashboard.css";
import Header from '../components/header/Header';
import Bodycontent from "../components/bodycontent/Bodycontent";
const dashboard = () => {
    return (
        <>
            <Header />
            <Bodycontent />
        </>
    );
};
export default dashboard;