import { useEffect, useState } from "react";
import "./CustomVoltageRedChart.css";
import Chart from "react-apexcharts";

const CustomVoltageRedChart = (props) => {
    const [chartconfig1, setChartconfig1] = useState();
    console.log(props);
    useEffect(() => {
        
        setChartconfig1({
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: props.time,
                    title: {
                        text: "Time"
                    }
                },
                yaxis: {
                    title: {
                        text: "Voltage"
                    }
                },
                title:{
                    text:"Voltage wrt to Time",
                    align:"center"
                },
                legend:{
                    show:true
                },
                dataLabels:{
                    enabled:true
                }
            },
            series: [
                {
                    name: "series-1",
                    data: props.value
                }
            ],

        });
        
    }, [props]);
    return (
        <div>
            {chartconfig1 ? <Chart
                options={chartconfig1.options}
                series={chartconfig1.series}
                type="line"
                width="500"
            />:null}
        </div>
    );
}

export default CustomVoltageRedChart;