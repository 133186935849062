import "./Lineform.css";
import { Avatar, Card, Input, Button } from "react-rainbow-components";
import red from "../../images/red.png";
import blue from "../../images/blue.png";
import yellow from "../../images/yellow.png";

import { useEffect, useState } from "react";
const logoStyle = { width: 60, height: 60 };
const Lineform = (props) => {

    const [voltageRed, setVoltageRed] = useState();
    const [voltageYellow, setVoltageYellow] = useState();
    const [voltageBlue, setVoltageBlue] = useState();
    const [currentRed, setCurrentRed] = useState();
    const [currentYellow, setCurrentYellow] = useState();
    const [currentBlue, setCurrentBlue] = useState();
    const [a_pRed, setA_pRed] = useState();
    const [a_pYellow, setA_pYellow] = useState();
    const [a_pBlue, setA_pBlue] = useState();
    const [r_pRed, setR_pRed] = useState();
    const [r_pYellow, setR_pYellow] = useState();
    const [r_pBlue, setR_pBlue] = useState();
    const [pfRed, setPfRed] = useState();
    const [pfYellow, setPfYellow] = useState();
    const [pfBlue, setPfBlue] = useState();
    useEffect(() => {
        setInterval(() => {
            fetch("https://vishnusubstationbackend.onrender.com/api/sensordata")
                .then(async result => {
                    const data = await result.json();
                    setVoltageRed(data.voltageRed);
                    setVoltageYellow(data.voltageYellow);
                    setVoltageBlue(data.voltageBlue);
                    setCurrentRed(data.currentRed);
                    setCurrentYellow(data.currentYellow);
                    setCurrentBlue(data.currentBlue);
                    setA_pRed(data.a_pRed);
                    setA_pYellow(data.a_pYellow);
                    setA_pBlue(data.a_pBlue);
                    setPfRed(data.pfRed);
                    setPfYellow(data.pfYellow);
                    setPfBlue(data.pfBlue);
                    //console.log(data);
                })
                .catch(error => {
                    console.error(error);
                });
        }, 2000);
    }, []);

    useEffect(() => {
        const R_PRed = 3 * voltageRed * currentRed * Math.sin(Math.acos(pfRed));
        setR_pRed(R_PRed);
    }, [voltageRed, currentRed, pfRed]);

    useEffect(() => {
        const R_PYellow = 3 * voltageYellow * currentYellow * Math.sin(Math.acos(pfYellow));
        setR_pYellow(R_PYellow);
    }, [voltageYellow, currentYellow, pfYellow]);

    useEffect(() => {
        const R_PBlue = 3 * voltageBlue * currentBlue * Math.sin(Math.acos(pfBlue));
        setR_pBlue(R_PBlue);
    }, [voltageBlue, currentBlue, pfBlue]);
    return (
        <div className="lineform__container">
            <Card className="parent__card">
                <Card className="child__card1">
                    <div className="child__card__paraheading">
                        <h4>KOVVADA LINE</h4>
                    </div>
                    <div className="line__names row">
                        <div className="col-3 head">

                        </div>
                        <div className="col-3 head">
                            <h3 className="red__name">RED</h3>
                        </div>
                        <div className="col-3 head">
                            <h3 className="yellow__name">YELLOW</h3>
                        </div>
                        <div className="col-3 head">
                            <h3 className="blue__name">BLUE</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 head1">

                        </div>
                        <div className="col-3 head1">
                            <Avatar className='logo' style={logoStyle} src={red} />
                        </div>
                        <div className="col-3 head1">
                            <Avatar className='logo' style={logoStyle} src={yellow} />
                        </div>
                        <div className="col-3 ">
                            <Avatar className='logo' style={logoStyle} src={blue} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 head1">
                            <h4 className="card_text">VOLTAGE</h4>
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={voltageRed} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__vr__input box" />
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={voltageYellow} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__vy__input box" />
                        </div>
                        <div className="col-3 ">
                            <Input label="" value={voltageBlue} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__vb__input box" />
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-3 head1">
                            <h4 className="card_text">CURRENT</h4>
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={currentRed} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__cr__input box" />
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={currentYellow} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__cy__input box" />
                        </div>
                        <div className="col-3 ">
                            <Input label="" value={currentBlue} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__cb__input box" />
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-3 head1">
                            <h4 className="card_text">ACTIVE POWER</h4>
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={a_pRed} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__ar__input box" />
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={a_pYellow} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__ay__input box" />
                        </div>
                        <div className="col-3 ">
                            <Input label="" value={a_pBlue} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__ab__input box" />
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-3 head1">
                            <h4 className="card_text">REACTIVE POWER</h4>
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={r_pRed} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__rr__input box" />
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={r_pYellow} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__ry__input box" />
                        </div>
                        <div className="col-3 ">
                            <Input label="" value={r_pBlue} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__rb__input box" />
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-3 head1">
                            <h4 className="card_text">POWER FACTOR</h4>
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={pfRed} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__pr__input box" />
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={pfYellow} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__py__input box" />
                        </div>
                        <div className="col-3 ">
                            <Input label="" value={pfBlue} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__pb__input box" />
                        </div>
                    </div>
                    <br></br>
                    <div className="line__names row">
                        <div className="col-3 head">
                            <h4 className="card_text button_txt">GRAPHS</h4>
                        </div>
                        <div className="col-3 head">
                            <Button
                                label="Red"
                                onClick={() => props.cnohandler(1)}
                                variant="brand"
                                className="rainbow-m-around_medium graph__button1"
                            />
                        </div>
                        <div className="col-3 head">
                            <Button
                                label="Yellow"
                                onClick={() => props.cnohandler(2)}
                                variant="brand"
                                className="rainbow-m-around_medium graph__button1"

                            />
                        </div>
                        <div className="col-3 head">
                            <Button
                                label="Blue"
                                onClick={() => props.cnohandler(3)}
                                variant="brand"
                                className="rainbow-m-around_medium graph__button1"
                            />
                        </div>
                    </div>
                    <br></br>
                </Card>
                <Card className="child__card2">
                    <div className="child__card__paraheading">
                        <h4>GOLLALAKODERU LINE</h4>
                    </div>
                    <div className="line__names row">
                        <div className="col-3 head">

                        </div>
                        <div className="col-3 head">
                            <h3 className="red__name">RED</h3>
                        </div>
                        <div className="col-3 head">
                            <h3 className="yellow__name">YELLOW</h3>
                        </div>
                        <div className="col-3 head">
                            <h3 className="blue__name">BLUE</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 head1">

                        </div>
                        <div className="col-3 head1">
                            <Avatar className='logo' style={logoStyle} src={red} />
                        </div>
                        <div className="col-3 head1">
                            <Avatar className='logo' style={logoStyle} src={yellow} />
                        </div>
                        <div className="col-3 head1 ">
                            <Avatar className='logo' style={logoStyle} src={blue} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 head1">
                            <h4 className="card_text">VOLTAGE</h4>
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={voltageRed} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__vr__input box" />
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={voltageYellow} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__vy__input box" />
                        </div>
                        <div className="col-3 ">
                            <Input label="" value={voltageBlue} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__vb__input box" />
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-3 head1">
                            <h4 className="card_text">CURRENT</h4>
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={currentRed} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__cr__input box" />
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={currentYellow} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__cy__input box" />
                        </div>
                        <div className="col-3 ">
                            <Input label="" value={currentBlue} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__cb__input box" />
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-3 head1">
                            <h4 className="card_text">ACTIVE POWER</h4>
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={a_pRed} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__ar__input box" />
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={a_pYellow} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__ay__input box" />
                        </div>
                        <div className="col-3 ">
                            <Input label="" value={a_pBlue} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__ab__input box" />
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-3 head1">
                            <h4 className="card_text">REACTIVE POWER</h4>
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={r_pRed} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__rr__input box" />
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={r_pYellow} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__ry__input box" />
                        </div>
                        <div className="col-3 ">
                            <Input label="" value={r_pBlue} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__rb__input box" />
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-3 head1">
                            <h4 className="card_text">POWER FACTOR</h4>
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={pfRed} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__pr__input box" />
                        </div>
                        <div className="col-3 head1">
                            <Input label="" value={pfYellow} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__py__input box" />
                        </div>
                        <div className="col-3 ">
                            <Input label="" value={pfBlue} disabled className="rainbow-m-vertical-p-horizontal_medium rainbow-m_auto form__pb__input box" />
                        </div>
                    </div>
                    <br></br>
                    <div className="line__names row">
                        <div className="col-3 head button_txt">
                            <h4 className="card_text">GRAPHS</h4>
                        </div>
                        <div className="col-3 head">
                            <Button
                                label="Red"
                                onClick={() => props.cnohandler(1)}
                                variant="brand"
                                className="rainbow-m-around_medium graph__button1"
                            />
                        </div>
                        <div className="col-3 head">
                            <Button
                                label="Yellow"
                                onClick={() => props.cnohandler(2)}
                                variant="brand"
                                className="rainbow-m-around_medium graph__button1"
                            />
                        </div>
                        <div className="col-3 head">
                            <Button
                                label="Blue"
                                onClick={() => props.cnohandler(3)}
                                variant="brand"
                                className="rainbow-m-around_medium graph__button1"
                            />
                        </div>
                    </div>
                    <br></br>
                </Card>
            </Card>
        </div>
    );
};
export default Lineform;