import { useEffect, useState } from "react";
import "./CustomReactivePowerRedChart.css";
import Chart from "react-apexcharts";

const CustomReactivePowerRedChart = (props) => {
    const [chartconfig4, setChartconfig4] = useState();
    console.log(props);
    useEffect(() => {
        setChartconfig4({
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: props.time,
                    title: {
                        text: "Time"
                    }
                },

                yaxis: {
                    title: {
                        text: "ReactivePower"
                    }
                },
                title: {
                    text: "ReactivePower wrt to Time",
                    align: "center"
                },
                legend: {
                    show: true
                },
                dataLabels: {
                    enabled: true
                }
            },
            series: [
                {
                    name: "series-1",
                    data:props.value
                }
            ]
        });

    }, [props]);
    return (
        <div className="graph">
            {chartconfig4 ? <Chart
                options={chartconfig4.options}
                series={chartconfig4.series}
                type="line"
                width="500"
            /> : null}
        </div>
    );
}

export default CustomReactivePowerRedChart;