import "./Bodycontent.css";
import Marquee from "react-fast-marquee";
import Lineform from "./Lineform";
import { useState } from "react";
import CustomModal from "./CustomModal";
const Bodycontent = (props) => {
    const[chartno,setChartNo]=useState();
    const[isOpen,setIsOpen]=useState();

    const chartNoHandler=(cno)=>{
        setChartNo(cno);
        setIsOpen(!isOpen);
    }
    return (
        <>
            <div className="bodycontent__container">
                <Lineform  cnohandler={chartNoHandler}/>
                <CustomModal isopen={isOpen}/>
            </div>
            <Marquee>
                <div className="marquee">
                    <span>This project is executed by Surendra, Rohit, Deva Sai kiran, Prabhu, Chaitanya, Kotesh, Harsha under the mentorship of Dr.V.S.N. Narasimha Raju.</span>
                </div>
            </Marquee>
        </>
    );
};
export default Bodycontent;