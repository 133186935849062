import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './pages/Dashboard';
const App = () => {
  return (
    <div className="App">
      <Dashboard />
    </div>
  );
};

export default App;
